<template>
  <vx-card :title="this.pageTitle">
    <template slot="actions">
      <div>
        <vs-button
            v-if="$can('create', 'product')"
            size="small" color="success" type="border" @click="createScheduledReport()"
        >
          Crear Reporte Programado
        </vs-button>
      </div>
    </template>

    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-table max-items="10" pagination search stripe :data="scheduledReports" :hoverFlat="true">
          <template slot="thead">
            <vs-th sort-key="name">Nombre</vs-th>
            <vs-th sort-key="periodicity">Frecuencia</vs-th>
            <vs-th sort-key="recipients">Destinatarios</vs-th>
            <vs-th>Acciones</vs-th>
          </template>

          <template slot-scope="{data}">
            <tbody>
            <vs-tr :data="record" :key="index" v-for="(record, index) in data">
              <vs-td>
                <p class="font-medium">{{ record.name }}</p>
              </vs-td>
              <vs-td>
                <p class="font-medium">{{ record.periodicity | periodicity_label(record.execution_hour, record.dates) }}</p>
              </vs-td>
              <vs-td>
                <p v-if="record.notification_method === 'all-recipients' " class="font-medium">Todos los destinatarios </p>
                <p v-for="recipient in record.recipients" class="font-medium">{{ recipient }}</p>
              </vs-td>
              <vs-td>
                <div class="flex">
                  <vx-tooltip text="Editar">
                    <vs-button
                        radius size="large" color="success" type="flat" icon-pack="feather" icon="icon-edit"
                        @click="editScheduledReport(record)"
                    />
                  </vx-tooltip>
                  <vx-tooltip text="Eliminar">
                    <vs-button
                        radius size="large" color="warning" type="flat" icon-pack="feather" icon="icon-trash-2"
                        @click="confirmDelete(record.id)"
                    />
                  </vx-tooltip>
                </div>
              </vs-td>
            </vs-tr>
            </tbody>
          </template>
        </vs-table>
      </div>
    </div>
  </vx-card>
</template>

<script>
  import NotificationSettingsClient from "../../utils/notificationSettingsClient";
  import Notifier from "./../../utils/notification";

  let notifier = new Notifier();
  const NOTIFICATION_SETTINGS_CLIENT = new NotificationSettingsClient()

  export default {
    data() {
      return {
        entryTypeList: {
          "assets": {
            "name": "Activos Eventuales",
            "entry_type": "assets"
          },
          "static-pages": {
            "name": "Páginas estáticas",
            "entry_type": "static-pages"
          },
          "jobs": {
            "name": "Plazas Disponibles",
            "entry_type": "jobs"
          },
          "promotions": {
            "name": "Promociones",
            "entry_type": "promotions"
          },
          "products": {
            "name": "Productos",
            "entry_type": "products"
          },
          "projects": {
            "name": "Proyectos Inmobiliarios",
            "entry_type": "projects"
          }
        },
        pageTitle: "Reportes Programados",
        scheduledReports: [],
        newScheduledReport: {
          id: null,
          name: "",
          periodicity: "",
          execution_hour: null,
          dates: [],
          recipients: [],
        },
        popupActive: false,
      };
    },

    mounted() {
      this.$vs.loading();
      this.setPageTitle()
      this.getScheduledReports()
          .then(() => {
            this.$vs.loading.close();
          });
    },

    methods: {
      setPageTitle() {
        let entry_type = this.$route.params["entry_type"]
        let val = this.entryTypeList[entry_type]
        this.pageTitle = `Reportes Programados: ${val.name}`
      },

      async getScheduledReports(showLoading = true) {
        if (showLoading) this.$vs.loading();

        await NOTIFICATION_SETTINGS_CLIENT.all_scheduled_reports(this.$route.params["entry_type"])
            .then(response => {
              this.scheduledReports = response.data;
              if (showLoading) this.$vs.loading.close();
            })
            .catch(error => {
              if (showLoading) this.$vs.loading.close();
              notifier.notification("error");
            });
      },

      async deleteScheduledReport(scheduledReportId) {
        if (scheduledReportId !== null && scheduledReportId !== undefined && scheduledReportId !== "") {
          this.$vs.loading();
          await NOTIFICATION_SETTINGS_CLIENT.delete_scheduled_report(
              this.$route.params["entry_type"], scheduledReportId
          )
              .then(response => {
                notifier.notification("deleted");
                this.getScheduledReports();
              })
              .catch(error => {
                notifier.alertMessage("error");
              });
          this.$vs.loading.close();
        }
      },

      confirmDelete(scheduledReportId) {
        notifier.deleteMessage().then(result => {
          if (result.value) {
            this.deleteScheduledReport(scheduledReportId);
          }
        });
      },

      createScheduledReport() {
        this.$router.push({
          name: "create-scheduled-report-notifications-settings",
          params: {
            entry_type: this.$route.params.entry_type
          }
        });
      },

      editScheduledReport(scheduledReport) {
        this.$router.push({
          name: "update-scheduled-report-notifications-settings",
          params: {
            entry_type: this.$route.params.entry_type,
            id: scheduledReport.id,
          }
        });
      },
    },
  };
</script>

<style scoped>
  .vs-table--tbody-table .tr-values.selected {
    cursor: default;
  }
</style>